<script>
/***
 Name: task_list
 Code: sa0ChunLuyu
 Time: 2021/11/22 16:31
 Remark: 任务管理
 */
import list from './list/list.vue'
import create from './edit/create.vue'
import update from './edit/update.vue'
import yp_list from './edit/yp_list.vue'

export default {
  components: {
    list, create, update, yp_list
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    listReload() {
      this.$refs.list.getTaskList()
    }
  }
}
</script>
<template>
  <div>
    <a-space align="start">
      <list ref="list"></list>
      <update :listReload="listReload" v-if="Number($route.params.id) !==0"></update>
      <yp_list v-if="Number($route.params.id) ===0"></yp_list>
    </a-space>
  </div>
</template>
<style scoped>

</style>
