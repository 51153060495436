<script>
export default {
  name: "yp_list",
  data() {
    return {
      list_search: {
        page: 1,
        type: 0,
        search: '',
        edit: 1,
      },
      list: [],
      size: 20,
      count: 0,

      search: '',
      page: 1,
    }
  },
  mounted() {
    this.mountedDo()
  },
  watch: {
    $route: {
      handler() {
        // 深度监听 路由变化
        this.mountedDo()
      },
      deep: true
    },
  },
  methods: {
    searchClick() {
      this.$router.push({
        params: {
          id: 0
        },
        query: {
          ...this.list_search,
          pa: 1,
          sa: this.search
        }
      })
    },
    pageChange(e) {
      this.$router.push({
        params: {
          id: 0
        },
        query: {
          ...this.list_search,
          pa: e,
          sa: this.search
        }
      })
    },
    mountedDo() {
      this.list_search.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
      this.list_search.type = this.$route.query.type ? Number(this.$route.query.type) : 0;
      this.list_search.search = this.$route.query.search ? this.$route.query.search : '';
      this.list_search.edit = this.$route.query.edit ? Number(this.$route.query.edit) : 2;


      this.search = this.$route.query.sa ? this.$route.query.sa : '';
      this.page = this.$route.query.pa ? Number(this.$route.query.pa) : 1;
      this.getAllList()
    },
    getAllList() {
      // 全部样品列表
      this.$sa0.post({
        url: this.$api('全部样品列表'), data: {
          sa: this.search,
          pa: this.page,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.list = response.data.list
            this.count = response.data.count
            this.size = response.data.size
            this.page = response.data.page
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    }
  }
}
</script>
<template>
  <div>
    <div class="search_wrapper">
      <a-space>
        <a-input v-model="search"></a-input>
        <a-button @click="searchClick()">搜索</a-button>
      </a-space>
    </div>
    <div class="all_wrapper">
      <table class="layui-table" lay-size="sm">
        <thead>
        <tr>
          <th>相关任务</th>
          <th>样品类型</th>
          <th>样品名称</th>
          <th>样品编号</th>
          <th>采集地点</th>
          <th>经纬度</th>
          <th>采集人</th>
          <th>样品状态</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(item,key) in list">
          <tr v-for="(iitem,ikey) in item.children">
            <td v-if="ikey === 0" :rowspan="item.children.length">
              <router-link :to="`/addcheck/addcheck/${iitem.task_id}`">{{ item.task_name }}</router-link>
            </td>
            <td>
              {{ iitem.type_name }}
            </td>
            <td>
              {{ iitem.name }}
            </td>
            <td>{{ iitem.in_number }}</td>
            <td>{{ iitem.pick_space }}</td>
            <td>
              <a-space>
                <div v-if="iitem.jing_dot">
                  <b>经度</b>
                  <span>{{ iitem.jing_dot }}</span>
                </div>
                <div v-if="iitem.wei_dot">
                  <b>纬度</b>
                  <span>{{ iitem.wei_dot }}</span>
                </div>
              </a-space>
            </td>
            <td>{{ iitem.pick_name }} <span v-if="iitem.other_pick_per.length > 0">等{{
                iitem.other_pick_per.length
              }}人</span></td>
            <td>{{ iitem.status ? iitem.status_turn : '新添加' }}</td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <div v-if="list.length > 0">
      <div class="list_page_wrapper" v-if="count && count > size">
        <a-pagination v-model="page" @change="pageChange" :total="count" :defaultPageSize="size"/>
      </div>
    </div>

  </div>
</template>
<style scoped>
.search_wrapper {
  margin-left: 130px;
}

.list_page_wrapper {
  position: absolute;
  right: 10px;
  z-index: 1;
  bottom: 5px;
}

.all_wrapper {
  position: absolute;
  left: 280px;
  right: 10px;
  top: 30px;
  bottom: 40px;
  overflow-y: auto;
}

.layui-table {
  width: 100%;
}
</style>
