<template>
    <div>
        <!--:visible="visible"      赋值 显示变量-->
        <!--@ok="handleOk"          赋值 确认函数-->
        <!--@cancel="handleCancel"  赋值 取消函数-->
        <a-drawer :title="`${isDefault === 0 ?'默认':'选择'}标签模板`" width="62%" placement="right" :closable="true"
                  :visible="visible" @close="onClose">

            <div v-if="isDefault === 0" style="padding: 25px">
                <div class="tag-template">
                    <table class="layui-table" v-bind="list_default_tag">
                        <tr>
                            <th class="t-r"><b>模板名称</b></th>
                            <td >{{list_default_tag.name}}</td>
                        </tr>
                        <tr>
                            <th class="t-r"><b>纸张大小</b>(mm)</th>
                            <td>{{list_default_tag.paper_w}}×{{list_default_tag.paper_w}}</td>
                        </tr>
                        <tr>
                            <th class="t-r"><b>条码信息</b>(mm)</th>
                            <td>
                                <a-space v-if="list_default_tag.isshow_barcode == 1">
                                    <span>条码大小：{{list_default_tag.barcode_w}}×{{list_default_tag.barcode_h}}</span>
                                    <span>上边距：{{list_default_tag.barcode_top}}</span>
                                    <span>左边距：{{list_default_tag.barcode_left}}</span>
                                </a-space>
                            </td>
                        </tr>
                        <tr>
                            <th class="t-r"><b>二维码信息</b>(mm)</th>
                            <td>
                                <a-space v-if="list_default_tag.isshow_qrcode == 1">
                                    <span>条码大小：{{list_default_tag.qrcode_w}}×{{list_default_tag.qrcode_h}}</span>
                                    <span>上边距：{{list_default_tag.qrcode_top}}</span>
                                    <span>左边距：{{list_default_tag.qrcode_left}}</span>
                                </a-space>
                            </td>
                        </tr>
                        <tr>
                            <th class="t-r"><b>文本信息</b></th>
                            <td>
                                <a-space v-if="list_default_tag.isshow_text == 1">
                                    <span>文字大小(px)：{{list_default_tag.text_size}}</span>
                                    <span>上边距(mm)：{{list_default_tag.text_top}}</span>
                                    <span>左边距(mm)：{{list_default_tag.text_left}}</span>
                                </a-space>
                            </td>
                        </tr>
                        <tr>
                            <th class="t-r"><b>打印预览</b></th>
                            <td>
                                <!--
                                <div style="border: solid 1px #efefef; opacity: 0;" :style=" {
                                    width:list_default_tag.paper_w+'px',
                                    height:list_default_tag.paper_h+'px',
                                }" id="tiaoma">
                                    <img :src="list_default_tag.qrcode_url">
                                </div>-->
                                <div style="border: solid 1px #efefef; width: 260px; height: 260px"  id="tiaoma">
                                    <img :src="list_default_tag.qrcode_url">
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td>
                                <a-button type="primary" @click="printClick()">打印</a-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div v-if="isDefault === 1" style="padding: 25px">
                <div class="tag-template" v-for="(item,key) in list_Get_tag">
                    <table class="layui-table">
                        <tr >
                            <th class="t-r font-semibold"><b>模板名称</b></th>
                            <td>{{item.name}}</td>
                        </tr>
                        <tr>
                            <th class="t-r font-semibold"><b>纸张大小</b>(mm)</th>
                            <td>
                                <a-space>
                                    {{item.paper_w}}×{{item.paper_w}}
                                </a-space>
                            </td>
                        </tr>
                        <tr>
                            <th class="t-r font-semibold"><b>条码信息</b>(mm)</th>
                            <td>
                                <a-space v-if="item.isshow_barcode == 1">
                                    <span>条码大小：{{item.barcode_w}}×{{item.barcode_h}}</span>
                                    <span>上边距：{{item.barcode_top}}</span>
                                    <span>左边距：{{item.barcode_left}}</span>
                                </a-space>
                            </td>
                        </tr>
                        <tr>
                            <th class="t-r font-semibold"><b>二维码信息</b>(mm)</th>
                            <td>
                                <a-space v-if="item.isshow_qrcode == 1">
                                    <span>条码大小：{{item.qrcode_w}}×{{item.qrcode_h}}</span>
                                    <span>上边距：{{item.qrcode_top}}</span>
                                    <span>左边距：{{item.qrcode_left}}</span>
                                </a-space>
                            </td>
                        </tr>
                        <tr>
                            <th class="t-r font-semibold"><b>文本信息</b></th>
                            <td>
                                <a-space v-if="item.isshow_text == 1">
                                    <span>文字大小(px)：{{item.text_size}}</span>
                                    <span>上边距(mm)：{{item.text_top}}</span>
                                    <span>左边距(mm)：{{item.text_left}}</span>
                                </a-space>
                            </td>
                        </tr>
                    <tr>
                        <th class="t-r font-semibold"><b>打印预览</b></th>
                        <td>
<!--                            <div style="border: solid 1px #efefef; " :style=" {-->
<!--                    width:item.paper_w+'px',height:item.paper_h+'px'-->
<!--                }"   :id="`tiaoma${key}`"><img :src="item.qrcode_url"></div>-->

                            <div style="border: solid 1px #efefef;  width: 260px; height: 260px"    :id="`tiaoma${key}`"><img :src="item.qrcode_url"></div>
                        </td>
                    </tr>
                      <tr>
                          <th></th>
                          <td>
                              <a-button type="primary" @click="doPrintItem(key)">打印</a-button>
                          </td>
                      </tr>

                    </table>
                </div>
            </div>

        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example4
     Code: sa0ChunLuyu
     Time: 2021/10/16 10:19
     Remark: 编辑弹框示例
     */
    export default {
        // 接收 显示变量 和 确定 取消 函数
        props: ['visible', 'close', 'iiitem', 'iitem', 'isDefault'],
        data() {
            return {
                list_default_tag: [],
                list_Get_tag: [],
            }
        },
        mounted() {
            this.Get_default_tag()
            this.Get_tag()
        },
        methods: {
            doPrintItem(key){
                this.$sa0.print(`#tiaoma${key}`)
            },
            printClick() {
                this.$sa0.print('#tiaoma')
            },

            //选择
            getImg(item) {
                let data = {
                    i: item.demo_num,
                    pw: item.paper_w,
                    ph: item.paper_h,
                    bs: Number(item.isshow_barcode) !== 2,
                    qs: Number(item.isshow_qrcode) !== 2,
                    ts: Number(item.isshow_text) !== 2,
                }

                if (Number(item.isshow_barcode) !== 2) {
                    data.bw = item.barcode_w
                    data.bh = item.barcode_h
                    data.btm = item.barcode_top
                    data.blm = item.barcode_left
                }

                if (Number(item.isshow_qrcode) !== 2) {
                    data.qw = item.qrcode_w
                    data.qh = item.qrcode_h
                    data.qtm = item.qrcode_top
                    data.qlm = item.qrcode_left
                }
                if (Number(item.isshow_text) !== 2) {
                    data.tv = item.demo_text
                    data.tsi = item.text_size
                    data.ttm = item.text_top
                    data.tlm = item.text_left
                }
                let q_arr = [];
                // let url = 'http://aaa.aaa.aaa?aaa=1&aaa=2&aaa=2&aaa=2&aaa=2&aaa=2'
                // ['tsi=1','ttm=1','aaa=1','aaa=1','aaa=1']
                for (let i in data) {
                    q_arr.push(`${i}=${data[i]}`)
                }
                let q_str = q_arr.join('&')
                // 'aaa=1&aaa=2&aaa=2&aaa=2&aaa=2&aaa=2'
                let url = this.$api('qrCode') + '?' + q_str
                console.log(url)
                return url
            },

            //根据Id获取标签
            Get_tag() {
                console.log('this.iiitem',this.iiitem)
                this.$sa0.post({
                    url: this.$api('Get_tag'),
                    data: {
                        tag_type: 3,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            let name = this.iiitem.name
                            let serial_num = this.iitem.in_number
                            this.list_Get_tag = response.data.list.map((item)=>{
                                let data = JSON.parse(JSON.stringify(item));
                                data.demo_num = serial_num
                                data.demo_text = name
                                item.qrcode_url = this.getImg(data)
                                return item
                            });
                            console.log(this.list_Get_tag)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //获取默认标签
            Get_default_tag() {
                console.log({
                    tag_type: 3,
                    name: this.iiitem.name,
                    serial_num: this.iiitem.in_number
                })
                this.$sa0.post({
                    url: this.$api('Get_default_tag'),
                    data: {
                        tag_type: 2,
                        name: this.iiitem.name,
                        serial_num: this.iiitem.in_number
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            console.log('response.data.tag',JSON.stringify(response.data.tag))
                            let data = JSON.parse(JSON.stringify(response.data.tag));
                            let name = this.info.name
                            let serial_num = this.info.serial_num
                            data.demo_num = serial_num
                            data.demo_text = name
                            response.data.tag.qrcode_url = this.getImg(data)
                            this.list_default_tag = response.data.tag;
                            console.log(this.list_default_tag)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
        }
    }
</script>
<style>
    .tag-template{
        border: solid 1px #efefef;
        float: left;
        margin: 5px;
        width: 520px;
        /*padding: 15px 15px 15px 0;*/
        line-height: 2.6;
    }
    .t-r{text-align: right; padding-right: 25px}
    .layui-table,.layui-table td,.layui-table th {
        border: none;
    }
    .layui-table th{text-align: right}
</style>
