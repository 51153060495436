<script>
/***
 Name: add_test_item
 Code: sa0ChunLuyu
 Time: 2021/11/25 13:53
 Remark: 添加检测项目
 */
export default {
  props: ['checkReload'],
  data() {
    return {
      visible: false,
      info: false,
      visible_1: false,
      list: [],
      state_arr: ["新增", '待检测', '领取任务', '申领样品', '已领取', '申请退回样品', '开始检测'],
      itemlist: [],
      checklist: [],
      readlist: [],
      choose_ids: [],
      taskchecklist: [],
    }
  },
  mounted() {
    this.getReadList()
  },
  methods: {
    getReadList() {
      this.$sa0.post({
        url: this.$api('核对人员列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.readlist = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getTaskCheckList() {
      this.$sa0.post({
        url: this.$api('生成任务检测列表0302'),
        data: {
          task_id: this.$route.params.id,
          sample_id: this.info.id,
          item_ids: this.choose_ids,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.taskchecklist = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getChooseList() {
      this.$sa0.post({
        url: this.$api('获取选择的检测项目列表0302'),
        data: {item_ids: this.choose_ids}
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.checklist = response.data.list.map((item) => {
              return {
                check: false,
                id: item.id,
                item_name: item.item_name,
                method_name: item.method_name,
                remark: item.remark,
              }
            });
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    onChangeAll(e) {
      if (e.target.checked) {
        this.itemlist.map((item, key) => {
          this.itemlist[key].check = true
        })
      } else {
        this.itemlist.map((item, key) => {
          this.itemlist[key].check = false
        })
      }
    },
    onChangeAllFalse(e) {
      if (e.target.checked) {
        this.checklist.map((item, key) => {
          this.checklist[key].check = true
        })
      } else {
        this.checklist.map((item, key) => {
          this.checklist[key].check = false
        })
      }
    },
    getItemListByType(info) {
      this.info = info;

      this.getTaskItemArr();

      this.visible = true
    },
    handleOk() {
      this.getTaskCheckList()
      this.handleCancel()
    },
    handleCancel() {
      this.visible_1 = false
    },
    makeList(list) {
      return list.map((item) => {
        if (item.type === 1) {
          this.can_choose.push(item.id)
        }
        return {
          title: item.name,
          key: item.id,
          children: item.children && item.children.length > 0 ? this.makeList(item.children) : []
        }
      })
    },
    openvisible_1() {
      this.$sa0.post({
        url: this.$api('样品类型列表0302')
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.can_choose = [];
            this.list = this.makeList(response.data.list);
            this.visible_1 = true
            this.onSelect([this.info.spec_type])
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getTaskItemArr() {
      this.choose_ids = []
      this.$sa0.post({
        url: this.$api('获取任务检测项目0302'),
        data: {
          task_id: this.$route.params.id,
          sample_id: this.info.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.choose_ids = response.data.list
            this.getTaskCheckList();
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    onClose() {
      this.visible = false
    },
    pushItem() {
      this.itemlist.map((item, key) => {
        if (item.check) {
          if (this.choose_ids.indexOf(item.id) === -1) {
            this.choose_ids.push(item.id)
          }
        }
      })
      this.getChooseList()
    },
    delline(key) {
      this.taskchecklist.splice(key, 1)
    },
    pullItem() {
      this.checklist.map((item, key) => {
        if (item.check) {
          let i = this.choose_ids.indexOf(item.id)
          if (i !== -1) {
            this.choose_ids.splice(i, 1)
          }
        }
      })
      this.getChooseList()
    },
    saveClick() {
      // 保存检测项目0302
      // taskchecklist
      let data = {
        data: [],
        task_id: this.$route.params.id,
        sample_id: this.info.id,
      }
      let list = [];
      this.taskchecklist.map((item) => {
        if (item.check_pers.length > 0 && item.read_pers.length > 0) {
          list.push({
            id: item.id,
            check_per: item.check_pers[item.check_pers.length - 1],
            read_per: item.read_pers[item.read_pers.length - 1],
            check_item: item.check_item,
            item_name: item.item_name,
          })
        }
      })
      if (list.length !== this.taskchecklist.length) {
        return layer.msg('请选择检测人或核对人');
      }
      data.data = list
      this.$sa0.post({
        url: this.$api('保存检测项目0302'),
        data
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.getTaskItemArr()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    onSelect(e) {
      if (e.length > 0) {
        let id = e[0];
        this.$sa0.post({
          url: this.$api('样品类型检测项目列表0302'),
          data: {
            item_id: id
          }
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.itemlist = response.data.list.map((item) => {
                return {
                  check: this.choose_ids.indexOf(item.id) !== -1,
                  id: item.id,
                  item_name: item.item_name,
                  method_name: item.method_name,
                  remark: item.remark,
                }
              });
              this.pushItem()
              // this.itemlist
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      }
    }
  }
}
</script>
<template>
  <div>
    <a-modal
        title="选择检测项目"
        :visible="visible_1"
        width="90%"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <div>
        <a-space align="start">
          <div class="tree_wrapper">
            <a-tree
                v-if="visible_1"
                :tree-data="list"
                @select="onSelect"
                :auto-expand-parent="true"
                :default-expanded-keys="info.spec_types"
            />
          </div>
          <div>
            <table class="layui-table" lay-size="sm">
              <thead>
              <tr>
                <th>
                  <a-checkbox @change="onChangeAll"></a-checkbox>
                </th>
                <th>项目名称</th>
                <th>方法名称</th>
                <th>备注</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,key) in itemlist">
                <td>
                  <a-checkbox v-model="item.check"></a-checkbox>
                </td>
                <td>{{ item.item_name }}</td>
                <td>{{ item.method_name }}</td>
                <td>{{ item.remark }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div style="padding-top: 200px;">
            <div>
              <a-button @click="pushItem()" size="small" class="my-1" style="width: 30px;">></a-button>
            </div>
            <div>
              <a-button @click="pullItem()" size="small" class="my-1" style="width: 30px;"><</a-button>
            </div>
          </div>
          <div>
            <table class="layui-table" lay-size="sm">
              <thead>
              <tr>
                <th>
                  <a-checkbox @change="onChangeAllFalse"></a-checkbox>
                </th>
                <th>项目名称</th>
                <th>方法名称</th>
                <th>备注</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,key) in checklist">
                <td>
                  <a-checkbox v-model="item.check"></a-checkbox>
                </td>
                <td>{{ item.item_name }}</td>
                <td>{{ item.method_name }}</td>
                <td>{{ item.remark }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </a-space>
      </div>
    </a-modal>

    <a-drawer
        title="添加检测"
        placement="bottom"
        :visible="visible"
        height="800px"
        @close="onClose"
    >
      <div>
        <div>
          <a-space>
            <a-button @click="openvisible_1()">选择检测项目</a-button>
            <a-button @click="saveClick()" type="primary">保存</a-button>
          </a-space>
        </div>
        <div>
          <table class="layui-table">
            <thead>
            <tr>
              <th>检测项目</th>
              <th>检测人</th>
              <th>校对人</th>
              <th>状态</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,key) in taskchecklist" :key="key">
              <td>{{ item.item_name }}</td>
              <td>
                <a-cascader :disabled="item.status !==0 && item.status !== 1" style="width: 300px;"
                            v-model="item.check_pers"
                            :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                            :options="item.admin_list"
                            placeholder="请选择检测人"/>
              </td>
              <td>
                <a-cascader :disabled="item.status !==0 && item.status !== 1" style="width: 300px;"
                            v-model="item.read_pers"
                            :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                            :options="readlist"
                            placeholder="请选择核对人"/>
              </td>
              <td>{{ state_arr[Number(item.status)] }}</td>
              <td>
                <a-button size="small" type="danger" @click="delline(key)" v-if="item.status == 0 || item.status == 1">
                  移除
                </a-button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </a-drawer>
  </div>
</template>
<style scoped>
.tree_wrapper {
  width: 200px;
  border-right: 1px solid silver;
  height: 600px;
  overflow-y: auto;
}
</style>
