<script>
/***
 Name: list
 Code: sa0ChunLuyu
 Time: 2021/11/22 16:34
 Remark: 任务管理 左侧列表
 */
export default {
  data() {
    return {
      list: [],
      size: 20,
      count: 0,
      edit: 1,
      create_show: false,
      page_first: true,
      list_search: {
        page: 1,
        type: 0,
        search: ''
      },
      before_data: {
        name: '',
        number: '',
        id: 0,
        type: 1
      }
    }
  },
  mounted() {
    // 页面初始化 执行的函数
    this.mountedDo()
  },
  watch: {
    $route: {
      handler() {
        // 深度监听 路由变化
        this.mountedDo()
      },
      deep: true
    },
    list_search: {
      handler() {
        // 深度监听 列表参数 的变量
        if (!this.page_first) {
          // 如果 page_first 是 true (首次打开)  就不会执行
          let {page, type, search} = this.list_search
          this.routerPush(page, type, search, this.$route.params.id, this.edit)
        }
      },
      deep: true
    }
  },
  methods: {
    routerPush(page, type, search, id, edit) {
      // router 推送
      // page 页数
      // type 获取类型
      // search 搜索名称或者编号
      // id 编辑的 任务ID
      // edit 编辑类型  1-基础信息 2-样品信息
      this.$router.push({
        params: {
          id
        },
        query: {
          page, type, search, edit, time: new Date() / 1
        }
      })
    },
    mountedDo() {
      // 开始读取 URL 上面的参数 page - 页数 type - 类型 search - 搜索 edit - 编辑类型 1-基础信息 2-样品信息
      this.list_search.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
      this.list_search.type = this.$route.query.type ? Number(this.$route.query.type) : 0;
      this.list_search.search = this.$route.query.search ? this.$route.query.search : '';
      this.edit = this.$route.query.edit ? this.$route.query.edit : 2;
      // 读取完成之后 开始执行 获取 任务委托单列表的函数
      this.getTaskList()
    },
    getTaskList() {
      // 任务委托单 分页列表 https://onespoon.coding.net/p/lims/wiki/426
      // 构建 data
      let data = this.list_search;
      // 执行 POST
      this.$sa0.post({
        url: this.$api('委托单分页列表'), data
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            // 按照 接口返回的数据 给本地的变量 赋值
            this.list = response.data.list
            this.size = response.data.size
            this.count = Number(response.data.count)
            this.list_search.page = response.data.page
            // 赋值完成 之后  打开 对 list_search 的深度监听
            setTimeout(() => {
              this.page_first = false
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    createBefore() {
      // 创建预委托单 type 1-创建完成之后 跳转到 基础信息 2-创建完成之后 跳转到 样品列表
      this.$sa0.post({
        url: this.$api('创建新预委托单'),
        data: {
          name: this.before_data.name,
          number: this.before_data.number,
          task: this.before_data.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.create_show = false
            this.routerPush(
                this.list_search.page,  // 页数
                this.list_search.type,  // 获取类型
                this.list_search.search,  // 搜索名称或者编号
                response.data.id, // 编辑的 任务ID
                this.before_data.type // 编辑类型  1-基础信息 2-样品信息
            )
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    createNewTask(id = 0) {
      this.$sa0.localStorage.set('COPYTASK', id)
      this.jumpToCreate();
    },
    createNewBefore(type, id = 0) {
      this.before_data.type = type
      this.before_data.id = id
      this.create_show = true
    },
    jumpToCreate() {
      this.routerPush(
          this.list_search.page,  // 页数
          this.list_search.type,  // 获取类型
          this.list_search.search,  // 搜索名称或者编号
          0, // 编辑的 任务ID
          2
      )
    },
    jumpToEdit(id) {
      this.routerPush(
          this.list_search.page,  // 页数
          this.list_search.type,  // 获取类型
          this.list_search.search,  // 搜索名称或者编号
          id, // 编辑的 任务ID
          2
      )
    },
    closeBefore() {
      this.create_show = false
    },
    createBeforeDo() {
      this.createBefore()
    },
  }
}
</script>
<template>
  <div>
    <div class="task_title_wrapper">委托单列表</div>
    <div class="task_list_wrapper p-1">
      <div>
        <a-input-search placeholder="搜索" v-model.lazy="list_search.search" class="w-full"/>
      </div>
      <div class="mt-1">
        <a-select class="w-full" v-model="list_search.type">
          <a-select-option :value="0">全部类型</a-select-option>
          <a-select-option :value="1">委托单</a-select-option>
          <a-select-option :value="2">预委托单</a-select-option>
        </a-select>
      </div>
      <div class="list_main_wrapper px-1">
        <a-list bordered :data-source="list">
          <a-list-item slot="renderItem" slot-scope="item, key">
            <div  @click="jumpToEdit(item.id)" class="w-full truncate cursor-pointer">
              <a-space>
                <i :class="[Number(item.type)===1?'czs-chemistry':'czs-chemistry-l']"></i>
                <span v-show="item.id !== Number($route.params.id)">{{ item.name }}</span>
                <b v-show="item.id === Number($route.params.id)">{{ item.name }}</b>
              </a-space>
            </div>
          </a-list-item>
        </a-list>
      </div>
      <div class="list_page_wrapper" v-if="count && count > size">
        <a-pagination simple v-model="list_search.page" :total="count" :defaultPageSize="size"/>
      </div>
    </div>
  </div>
</template>
<style scoped>
.list_page_wrapper {
  position: absolute;
  bottom: 10px;
}

.list_main_wrapper {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  top: 76px;
  overflow-y: auto;
}

.task_list_wrapper {
  width: 250px;
  position: absolute;
  bottom: 0;
  top: 40px;
  border: #eeeeee 1px solid;
  border-radius: 6px;
}

.create_task_wrapper {
  width: 150px;
  float: left;
}

.create_button_wrapper {
  font-weight: bold;
  font-size: 15px;
  width: 250px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  overflow: hidden;
  border-radius: 6px;
}

.task_title_wrapper {
  font-size: 24px;
  font-weight: bold;
}

.ant-list-bordered {

  border-radius: 4px 4px 0 0;
}
.ant-list-bordered{border: none !important}
.ant-list-bordered .ant-list-item{ padding: 10px}
</style>
