<script>
import moment from "moment";

/***
 Name: create
 Code: sa0ChunLuyu
 Time: 2021/11/22 21:42
 Remark: 编辑任务 创建
 */
import chooseTpl from './choose_tpl.vue'
import addSample from "./add_sample.vue";
import editSample from "./edit_sample.vue";
import addTestItem from "./add_test_item.vue";
import editTestItem from "./edit_test_item.vue";
import printTagdefault from './printTag-default/printTag-default.vue';//w选择打印标签
import NumberrulelistWTD from './numberrulelist-WTD/numberrulelist-WTD.vue';//w选择其它编号规则

export default {
  components: {chooseTpl, addSample, editSample, addTestItem, editTestItem, printTagdefault, NumberrulelistWTD},
  props: ['listReload'],
  data() {
    return {
      Numberrulelist: false,
      isDefault: '',
      print_show: false,
      edit: 1,
      task_info: false,
      sample_list: [],
      check_list: [],
      fz_list: [],
      task_content: [],
      list_search: {
        page: 1,
        type: 0,
        search: ''
      }
    }
  },
  watch: {
    $route: {
      handler() {
        // 深度监听 路由变化
        this.mountedDo()
      },
      deep: true
    },
  },
  mounted() {
    this.mountedDo()
  },
  methods: {
    // 定义 关闭对话框时的 函数 打印标签
    onCloseDrawerP() {
      this.drawer_visible = false;
      this.print_show = false
    },
    // 定义 打开对话框时的 函数 打印标签
    showDrawerP(item, iitem, isDefault) {
      this.iitem = iitem
      let data = JSON.parse(JSON.stringify(item))
      data.name = iitem.name
      data.in_number = iitem.in_number
      this.iiitem = data
      this.isDefault = isDefault
      this.drawer_visible = true;
      setTimeout(() => {
        this.print_show = true
      })
      console.log(this.iiitem)
      console.log(this.iitem)
    },


    checkReload() {
      this.$refs.add_test_item.visible = false;
      this.$refs.edit_test_item.visible = false;
      this.mountedDo()
    },
    infoReload() {
      this.$refs.add_sample.visible = false;
      this.$refs.edit_sample.visible = false;
      this.$refs.add_sample.num_input_show = false;
      this.mountedDo()
    },
    mountedDo() {
      // 开始读取 URL 上面的参数 page - 页数 type - 类型 search - 搜索 edit - 编辑类型 1-基础信息 2-样品信息
      this.list_search.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
      this.list_search.type = this.$route.query.type ? Number(this.$route.query.type) : 0;
      this.list_search.search = this.$route.query.search ? this.$route.query.search : '';
      this.edit = this.$route.query.edit ? Number(this.$route.query.edit) : 2;
      // 读取完成之后 开始执行 获取 任务委托单列表的函数


      this.getTaskInfo()
      this.getFzList()
    },
    getFzList() {
      this.$sa0.post({
        url: this.$api('负责人列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.fz_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    routerPush(page, type, search, id, edit) {
      // router 推送
      // page 页数
      // type 获取类型
      // search 搜索名称或者编号
      // id 编辑的 任务ID
      // edit 编辑类型  1-基础信息 2-样品信息
      this.$router.push({
        params: {
          id
        },
        query: {
          page, type, search, edit
        }
      })
    },
    getTaskInfo() {
      // 获取委托单详情
      this.$sa0.post({
        url: this.$api('委托单详情'),
        data: {
          id: this.$route.params.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            // 对任务详情赋值
            this.task_info = response.data.info
            if (this.edit === 1) {
              // 开始解析 任务表单
              this.task_content = response.data.content.map((item) => {
                let con = item
                let content = con.table_content
                let value = ''
                // 1567 直等  234 解JSON
                switch (con.type) {
                  case 0:
                  case 1:
                  case 5:
                  case 6:
                  case 7:
                    content = con.table_content;
                    break;
                  case 2:
                  case 3:
                  case 4:
                    content = JSON.parse(con.table_content);
                    break;
                }
                // 12457 直等  36 解JSON
                switch (con.type) {
                  case 0:
                  case 1:
                  case 2:
                  case 4:
                  case 5:
                  case 7:
                    value = con.taskcontent
                    break;
                  case 3:
                  case 6:
                    value = JSON.parse(con.taskcontent)
                    break;
                }
                con.value = value;
                con.content = content;
                return con
              })
            } else {
              // 获取样品列表
              this.getSampleList()
              this.getCheckList()
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getCheckList() {
      this.$sa0.post({
        url: this.$api('任务检测列表'),
        data: {
          task_id: this.$route.params.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.check_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getSampleList() {
      this.$sa0.post({
        url: this.$api('任务样品列表'),
        data: {
          id: this.$route.params.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.sample_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    updateTable(edit) {
      // 设置 必填的字段
      let info_not_null = [
        'name',
        'task_num',
        'com_name',
      ];
      let info_title = {
        'name': '委托单名称',
        'task_num': '委托单编号',
        'com_name': '委托单位名称',
      }
      // 循环 基本信息的每一项 看看 必填项 填没填
      let err = ''
      for (let i in this.task_info) {
        // 先看字段 需不需要 必填
        if (info_not_null.indexOf(i) !== -1) {
          // 如果是空的  就给 err 赋值
          if (this.task_info[i] === '') err = info_title[i]
        }
      }
      // 判断 err 不为空 就是 有必填项 没填
      if (err !== '') return layer.msg(err + '不能为空')
      // 开始判断 自定义信息有没有必填项没填
      let content = this.task_content
      // 能到这里 说明 err = ''   所以没必要 重置  但是为了 代码清晰一点  还是写了。  自己写的时候  下面的一行 可以不要
      err = ''
      // 循环每一项 看看 必填的 有没有空的
      content.map((item) => {
        if (Number(item.not_null) === 1) {
          switch (item.type) {
            case 0:
            case 1:
            case 2:
            case 4:
            case 5:
            case 7:
              if (item.value === '') err = item.title;
              break;
            case 3:
              if (item.value.length === 0) err = item.title;
              break;
            case 6:
              item.value.map((i) => {
                if (i === '') err = item.title;
              })
              break;
          }
        }
      })
      if (err !== '' && Number(this.task_info.type) === 1) return layer.msg(err + '不能为空')
      let data = {
        ...this.task_info, // 把 task_info 里面的每一项 平摊赋值给 data
        table_arr: content.map((item) => {
          // 格式化 表单内容
          let value = item.value
          switch (item.type) {
            case 3:
            case 6:
              value = JSON.stringify(item.value)
              break;
          }
          return {
            form_word_id: item.form_word_id,
            taskcontent: value,
            not_null: item.not_null,
          }
        })
      }
      data.check_id = data.check_ids.length > 0 ? data.check_ids[data.check_ids.length - 1] : 0;
      data.check_ids = JSON.stringify(data.check_ids);
      // 赋值ID
      data.id = this.$route.params.id
      this.$sa0.post({
        url: this.$api('修改委托单'),
        data
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('修改成功')
            // 判断 按钮类型 然后 执行相关的操作
            this.routerPush(
                this.list_search.page,  // 页数
                this.list_search.type,  // 获取类型
                this.list_search.search,  // 搜索名称或者编号
                this.$route.params.id, // 编辑的 任务ID
                edit
            )
            this.mountedDo()
            this.listReload()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    imageUpload(e, key, k) {
      // 这个是 上传图片 的函数
      let param = new FormData()
      param.append('file', e.target.files[0])
      this.$sa0.file({
        url: this.$api('上传图片'),
        data: param
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          error: () => {
            layer.msg('上传失败')
          },
          then: (res) => {
            layer.msg('上传成功')
            this.$set(this.task_content[key].value, k, res.data.url)
          }
        })
      })
    },
    changeTpl() {
      // 数据深拷贝 赋值
      let ti = JSON.parse(JSON.stringify(this.$refs.choose_tpl.t_info))
      let tc = JSON.parse(JSON.stringify(this.$refs.choose_tpl.t_content))
      this.task_info = ti
      this.task_content = tc
      // 关闭 选模板的弹框
      this.$refs.choose_tpl.visible = false
    },
    chooseTpl() {
      // 让子组件 choose_tpl 获取列表
      this.$refs.choose_tpl.getTplList()
    },
    showAllYp() {
      this.routerPush(
          this.list_search.page,  // 页数
          this.list_search.type,  // 获取类型
          this.list_search.search,  // 搜索名称或者编号
          0, // 编辑的 任务ID
          2
      )
    },
    changeEdit(edit) {
      this.routerPush(
          this.list_search.page,  // 页数
          this.list_search.type,  // 获取类型
          this.list_search.search,  // 搜索名称或者编号
          this.$route.params.id, // 编辑的 任务ID
          edit
      )
    },
    addSampleClick() {
      // 打开 添加样品 的窗口
      this.$refs.add_sample.getSampleTypeList()
    },
    openEdit(info) {
      this.$refs.edit_sample.getSampleTypeList(info)
    },
    delThisDo(info) {
      this.$sa0.post({
        url: this.$api('删除样品'),
        data: {
          id: info.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('删除成功')
            // 判断 按钮类型 然后 执行相关的操作
            this.mountedDo()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    addTestItem(info) {
      this.$refs.edit_test_item.getItemListByType(info)
    },
    delThis(info) {
      let this_ = this
      layer.confirm('是否删除样品？', {
        btn: ['确定', '取消'] //按钮
      }, function () {
        this_.delThisDo(info)
      }, function () {
      });
    },
    openCheckEdit(info) {
      this.$refs.edit_test_item.getItemListByType(info)
    },
    delCheckThisDo(info) {
      this.$sa0.post({
        url: this.$api('删除任务检测'),
        data: {
          id: info.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('删除成功')
            // 判断 按钮类型 然后 执行相关的操作
            this.mountedDo()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    delCheckThis(info) {
      let this_ = this
      layer.confirm('是否删除检测？', {
        btn: ['确定', '取消'] //按钮
      }, function () {
        this_.delCheckThisDo(info)
      }, function () {
      });
    },

    //默认委托单编号w
    nummoren() {
      this.$sa0.post({
        url: this.$api('Get_default_serialnumber'),
        data: {
          num_type: 0,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.task_info.task_num = response.data.serialnumber;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    pushOrder(order) {
      this.task_info.task_num = order
      this.modalClose01();
    },
    // 定义 打开对话框时的 函数 //W编号规则弹窗
    showModal01() {
      this.Numberrulelist = true
    },
    // 定义 关闭对话框时的 函数 //W编号规则弹窗
    modalClose01() {
      this.Numberrulelist = false
    },
  }
}
</script>
<template>
  <div class="mt-10">
    <chooseTpl :changeTpl="changeTpl" :task_content="task_content" :task_info="task_info" ref="choose_tpl"></chooseTpl>
    <addSample :listReload="infoReload" ref="add_sample"></addSample>
    <editSample :listReload="infoReload" ref="edit_sample"></editSample>
    <addTestItem :checkReload="checkReload" ref="add_test_item"></addTestItem>
    <editTestItem :checkReload="checkReload" ref="edit_test_item"></editTestItem>
    <a-space align="start">
      <div v-if="edit === 2" class="edit2_wrapper">
        <div class="sample_area_wrapper" :style="{height:'100%'}">
          <div>
            <a-space>
              <a-button type="primary" @click="addSampleClick()">添加样品</a-button>
              <a-button type="primary" @click="showAllYp()">查看全部样品</a-button>
            </a-space>
          </div>
          <div>
            <table class="layui-table">
              <thead>
              <tr>
                <th>样品类型</th>
                <th>样品名称</th>
                <th>样品编号</th>
                <th>采集地点</th>
                <th>经纬度</th>
                <th>采集人</th>
                <th>样品状态</th>
                <th>操作</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(item,key) in sample_list">
                <tr v-for="(iitem,ikey) in item.children">
                  <td v-if="ikey === 0" :rowspan="item.children.length">
                    {{ item.type_name }}
                  </td>
                  <td v-if="ikey === 0" :rowspan="item.children.length">
                    {{ item.name }}
                  </td>
                  <td>{{ iitem.in_number }}</td>
                  <td>{{ iitem.pick_space }}</td>
                  <td>
                    <a-space>
                      <div v-if="iitem.jing_dot">
                        <b>经度</b>
                        <span>{{ iitem.jing_dot }}</span>
                      </div>
                      <div v-if="iitem.wei_dot">
                        <b>纬度</b>
                        <span>{{ iitem.wei_dot }}</span>
                      </div>
                    </a-space>
                  </td>
                  <td>{{ iitem.pick_name }} <span v-if="iitem.other_pick_per.length > 0">等{{
                      iitem.other_pick_per.length
                    }}人</span></td>
                  <td>{{ iitem.status ? iitem.status_turn : '新添加' }}</td>
                  <td>
                    <a-space>
                      <a-button @click="openEdit(iitem)" v-if="iitem.status === 1"
                                type="primary" size="small">编辑
                      </a-button>
                      <a-button @click="showDrawerP(item,iitem,1)" size="small">打印标签</a-button>
                      <a-button @click="addTestItem(iitem)" type="primary" size="small">检测设置</a-button>
                      <a-button @click="delThis(iitem)" type="danger" v-if="iitem.status === 1" size="small">删除
                      </a-button>
                    </a-space>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </a-space>

    <div v-if="print_show">
      <printTagdefault :iiitem="iiitem" :iitem="iitem" :isDefault="isDefault" :close="onCloseDrawerP"
                       :visible="drawer_visible"
      ></printTagdefault>
    </div>
    <NumberrulelistWTD :callback="pushOrder" :ok="modalClose01" :cancel="modalClose01"
                       :Numberrulelist="Numberrulelist"></NumberrulelistWTD>

  </div>
</template>
<style scoped>
.edit2_wrapper {
  position: absolute;
  bottom: 5px;
  top: 0;
  left: 290px;
  right: 10px;
}

.detection_area_wrapper {
  width: 100%;
  overflow-y: auto;
}

.sample_area_wrapper {
  width: 100%;
  overflow-y: auto;
}

.edit_tab_wrapper {
  width: 200px;
  height: 40px;
}

td {
  line-height: 40px;
  padding: 0 10px;
}

.input_wrapper {
  max-width: 400px;
  height: 100%;
  border: 1px solid #eeeeee;
  height: 36px;
  line-height: 36px;
  border-radius: 2px;
  padding: 5px;
}

.input_select_wrapper {
  width: 200px;
}

.table_title_bg {
  background: #FAFAFA;
}

.layui-table th {
  text-align: left
}

.layui-table td {
  padding: 0 10px;
  height: 40px;
  border-color: #ececec;
}

.content_image_item_wrapper {
  width: 600px;
}

.image_item_wrapper {
  position: relative;
  overflow: hidden;
  margin: 10px 10px 10px 0;
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #5a5e6630;
  background: #5a5e6605;
}

.image_image_wrapper img {
  width: 100%;
  height: 100%;
}

.image_image_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.image_input_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  cursor: pointer;
}

.input_line_wrapper {
  width: 500px;
  display: inline-block;
}
</style>
